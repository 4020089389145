/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "auditory-illusions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#auditory-illusions",
    "aria-label": "auditory illusions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Auditory Illusions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/presbycusis-hero.jpg",
    alt: "Leaves background hero",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people are familiar with optical illusions. They are tricks of the eye that make the brain think something is there when in reality there is nothing. But did you know that the ears can be tricked just as easily? Auditory illusions may not be as well-known as their optical cousins, but they are just as fascinating. What’s more, by studying them, we can get a much better idea of how the human auditory system works, helping to formulate better treatments and technology for common hearing problems."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-auditory-illusions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-auditory-illusions",
    "aria-label": "what are auditory illusions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are auditory illusions?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In auditory illusions, the human brain thinks that it can hear something that is either not “there” or exists in a very different form to how it is perceived. Normally, we rely on being able to translate the world around us into accurate sense perceptions. But in certain circumstances, the relationship between stimuli and senses breaks down, leading to some interesting results. Why can we hear “phantom” sounds?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The more we learn about how complex everyday hearing is, the more auditory illusions start to make sense. They could just be momentary disconnections in the way our minds and ears interact, as the brain processes fragments of sound into a continuous whole. Either way, these illusions are interesting to explore, so let’s have a look at some of the most famous."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "understanding-the-mcgurk-effect-what-you-see-and-hear-arent-the-same-thing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-the-mcgurk-effect-what-you-see-and-hear-arent-the-same-thing",
    "aria-label": "understanding the mcgurk effect what you see and hear arent the same thing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding the McGurk Effect: What you see and hear aren't the same thing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Amazingly, the way we visualize the world has an important impact on the things that we hear. This is neatly illustrated by one of the most famous auditory illusions: the McGurk Effect. Named after one of its discoverers (the British psychologist Harry McGurk), the effect is particularly common in conversation. It turns out that the way words are formed by the speaker is as important in how they are perceived as the sound that they make. So, if you shape your mouth in two different ways, but say the same word, viewers may well hear two separate sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below you can see an example of the McGurk Effect:"), "\n", React.createElement(VideoIframe, {
    title: "Better Hearing and Speech Month | Johns Hopkins Cochlear Implant Center",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/kzo45hWXRWU?start=6&end=57"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s hard to recreate this in real life. When you say words, your mouth tends to make very similar shapes, but skilled tricksters can use the McGurk Effect to fool people into hearing something they really can’t. The key to what’s actually happening is inside the hearer’s brain. We don’t just absorb sound waves and turn them instantly into sound, like a radio microphone might do. Instead, our brains anticipate and interpret what they see and hear, taking short cuts to produce the perception of sound. When what we see and hear don’t match up, illusions can result."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-shepard-illusion-what-goes-up-must-come-down",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-shepard-illusion-what-goes-up-must-come-down",
    "aria-label": "the shepard illusion what goes up must come down permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Shepard Illusion: What goes up must come down"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sometimes, auditory illusions are embedded deep within the music that we adore. Take the famous Shepard Tone, for example. Named after the California-based neuro-scientist Roger Shepard, the tone is a complex mixture of “sine waves” (the curvy audio waves you’ve probably have encountered in geometry class). These waves are constantly being raised in pitch, only to be dropped down an octave, like a kind of waterwheel mechanism."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below you can see an example of the Shepard Illusion:"), "\n", React.createElement(VideoIframe, {
    title: "Better Hearing and Speech Month | Johns Hopkins Cochlear Implant Center",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/kzo45hWXRWU?start=126&end=176"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you first hear the sound this creates, all you hear is a basic scale, with the pitch rising steadily. But if you replay that scale, something amazing happens. Instead of starting from the base note, it sounds as if the scale is constantly rising in pitch, which it isn’t actually doing, because the sine waves constantly cycle back downwards. What we hear is a scale ascending forever, a little like the stripes on a barber pole. But it’s a function of how our brain fails to assimilate complex audio information. You may well have heard the Shepard scale in action without realizing it. For instance, it’s commonly used to build a kind of oppressive atmosphere in movies (the 2017 war film “Dunkirk” used it throughout to create the feeling of peril and dread). And electronic pop musicians have used it regularly, too."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-tritone-paradox-everyone-hears-something-a-little-bit-different",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-tritone-paradox-everyone-hears-something-a-little-bit-different",
    "aria-label": "the tritone paradox everyone hears something a little bit different permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Tritone Paradox: Everyone hears something a little bit different"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Tritone Paradox is one of the most well-known variants of the Shepard Illusion, and it’s an auditory illusion that works best when you try it out on friends. In the normal Shepard Tone, a scale ascends seemingly forever. But with the Triton Paradox, you’ll hear a pair of notes played directly after each other, with a small gap in between called a “tritone.”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But these aren’t ordinary notes. As you might have guessed, they are a pair of Shepard Tones, and have the same magical properties as their ascending compatriots. According to British musicologist Diana Deutsch, these two tones can either be heard as ascending or descending notes. But here’s the interesting part: when you listen to them as a group, you’ll probably come up with different interpretations of which note was high, and which was low. Why is this? It’s still not exactly clear. However, according to Deutsch, the language you speak and the culture you grew up in plays a major role."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below you can see an example of the Tritone Paradox:"), "\n", React.createElement(VideoIframe, {
    title: "Better Hearing and Speech Month | Johns Hopkins Cochlear Implant Center",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/kzo45hWXRWU?start=75&end=126"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-you-hear-is-rarely-the-whole-story",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-you-hear-is-rarely-the-whole-story",
    "aria-label": "what you hear is rarely the whole story permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What you hear is rarely the whole story"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are plenty of other remarkable auditory illusions beside the Tritone Paradox, the McGurk Effect and the Shepard Tone, and more are being discovered all the time. For example, Diana Deutsch also looked into what is known as “pareidolia” – the perception of words or images which make sense from a chaotic, disorganized backdrop. Deutsch rigged up a pair of stereo speakers and played a two-word phrase through them, with one offset so that the first word is overlaid constantly by the second. What results shouldn’t make sense, but in experiments, people have heard “phantom” words emerging as their brains struggle to understand what they are hearing. Another fascinating illusion is the “Speech to Song” effect. In this case, the brain seems to turn ordinary speech into a form of song if a phrase is repeated often enough. But not just any phrase will do. Apparently, it needs to be a phrase that has been taken from a longer sentence, and then immediately repeated. After around five or six repetitions, the brain begins to interpret it in a musical way – something that audiologists still can’t really explain."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-do-we-experience-all-these-auditory-illusions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-we-experience-all-these-auditory-illusions",
    "aria-label": "why do we experience all these auditory illusions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do we experience all these auditory illusions?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The primary reason for auditory illusions is simple: the human ear and brain are capable of some amazing achievements, but it can only do so much. The exterior world is constantly changing and incredibly complex. The ear and brain have a lot of work to do if they want to interpret it and prime the body for action. Evolution has taken plenty of short cuts to ensure that humans can interpret their environment, but the process has left behind plenty of rough edges. These often show up as auditory illusions. And then there’s the fact that our hearing is limited. We can (generally) only hear sounds from around 20Hz to 20,000Hz. When tones slip out of or into that range, weird things happen (hence the endless sensation of the Shepard Tone)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-auditory-illusions-relevant-to-hearing-aid-design-and-prescription",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-auditory-illusions-relevant-to-hearing-aid-design-and-prescription",
    "aria-label": "are auditory illusions relevant to hearing aid design and prescription permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are auditory illusions relevant to hearing aid design and prescription?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Absolutely. In a way, modern hearing aids are a form of auditory illusion in themselves. Manufacturers are constantly trying to replicate the way the human ear works. They also seek to understand how to create electronic representations of data the human brain can perceive. Many hearing aid units use algorithms to process human speech, picking the sounds that seem to fit speech and amplifying them. The knowledge of how sounds behave and how they can be represented mathematically is a crucial aspect of designing devices that produce a reliable, accurate version of reality. So, we need to know how illusions are created. Even if they are fun to get to grips with, these illusions are more than simply tricks. Aside from helping to create exotic musical sounds and sound effects for movies, they contribute to hearing aid development, too."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
